.NotFound
{
    font-family: 'Open Sans', sans-serif;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    height: 100vh;

    color: #fff;
    background: #f8cb5e ;
}

.c
{
    position: relative;

    display: block;

    width: 80%;
    margin: 100px auto;

    text-align: center;
}

._404
{
    font-size: 220px;

    position: relative;
    z-index: 2;

    display: inline-block;

    height: 250px;

    letter-spacing: 15px;
}

._1
{
    font-size: 4em;
    line-height: 80%;

    position: relative;

    display: block;

    text-align: center;
    letter-spacing: 12px;
}

._2
{
    font-size: 20px;

    position: relative;

    display: block;

    text-align: center;
}

.text
{
    font-size: 70px;
    line-height: 1.2em;

    position: relative;
    z-index: 3;

    display: inline-block;

    width: 100%;
    margin: 19px 0 0 0;

    text-align: center;
}

.btn-nf
{
    font-size: 25px;

    position: relative;
    z-index: 5;

    display: inline-block;

    width: 358px;
    margin: 0 auto;
    margin-right: 10px;
    padding: 5px;

    text-decoration: none;

    color: #f8cb5e ;
    background-color: rgb( 255, 255, 255);
}

.right
{
    float: right;

    width: 60%;
}

.NotFound hr
{
    width: 420px;
    height: 10px;
    margin: 0 auto;
    padding: 0;

    text-align: center;

    color: #fff;
    border: none;
    border-top: 5px solid #fff;
}
