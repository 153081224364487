.verify
{
    font-family: 'Open Sans', sans-serif;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    height: 100vh;

    color: #fff;
    background: #ef5a20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify-container
{
    text-align: center;
    padding: 20px;
}

.verify-1
{
    font-size: 2.5em;

    position: relative;

    display: block;

    text-align: center;
    letter-spacing: 12px;
    line-height: 1;
}

.verify-2
{
    font-size: 26px;

    position: relative;

    display: block;

    text-align: center;
    margin: 20px 0;
    font-weight: lighter;
}

.text
{
    font-size: 70px;
    line-height: 1.2em;

    position: relative;
    z-index: 3;

    display: inline-block;

    width: 100%;
    margin: 19px 0 0 0;

    text-align: center;
}

.verify-btn-nf
{
    font-size: 25px;

    position: relative;
    z-index: 5;

    display: inline-block;

    width: 358px;
    margin: 0 10px 0 auto;
    padding: 5px;

    text-decoration: none;

    color: #ef5a20 ;
    background-color: rgb( 255, 255, 255);
}

.right
{
    float: right;

    width: 60%;
}

hr
{
    width: 420px;
    height: 10px;
    margin: 0 auto;
    padding: 0;

    text-align: center;

    color: #fff;
    border: none;
    border-top: 5px solid #fff;
}


/*Loader*/
html {
    box-sizing: border-box;
    font-family: "Lato, sans-serif";
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    font-family: 'Lato', sans-serif;
    color: #4d4d4d;
}

body {
    width: 100%;
    height: 100%;
    font-size: 1.6em;
}

.loader-wrapper {
    font-size: 1.6rem;
    background: #dad8d2;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.hourglass {
    display: block;
    background: #dad8d2;
    margin: 3em auto;
    width: 2em;
    height: 4em;
    box-shadow: inset #dad8d2 0 0 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 0 4em 0;
    -webkit-animation: hourglass 1s linear infinite;
    animation: hourglass 1s linear infinite;
}

.outer {
    fill: #ef5a20;
}

.middle {
    fill: #dad8d2;
}

@-webkit-keyframes hourglass {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 0 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 4em 0 0, inset #ef5a20 0 4em 0 0;
    }
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset #ef5a20 0 4em 0 0;
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset #ef5a20 0 4em 0 0;
    }
}

@keyframes hourglass {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 0 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 4em 0 0, inset #ef5a20 0 4em 0 0;
    }
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset #ef5a20 0 4em 0 0;
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        box-shadow: inset #dad8d2 0 2em 0 0, inset #ef5a20 0 2em 0 0, inset #dad8d2 0 2em 0 0, inset #ef5a20 0 4em 0 0;
    }
}
